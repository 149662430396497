import React, { Component } from "react"

class SimpleBlock extends Component {
    data;
    componentWillMount() {
        this.data = this.props.data.content;
    }

    render() {
        return <section className="simpleBlock">
            <div className="container">
                <div className="row">
                    <div className="col ">
                        <div  className='articleBodyTable simpleBlockText' dangerouslySetInnerHTML={{ __html: this.data }} />
                    </div>
                </div>
            </div>
        </section>
    }
}

export default SimpleBlock
