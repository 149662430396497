import React, { Component } from "react"
import SimpleBlock from "./simpleBlock"
class Blocks extends Component {

  blocks = {}
  media = {};

  componentWillMount() {
    this.blocks = this.props.data;
    this.media = this.props.media;
  }

  render() {
    return (
      <div>
        {(this.blocks.map(block => {
          switch (block.__typename) {
            case "jhipster_SimpleBlockDTO":
              return <SimpleBlock key={block.id} data={block} media={this.media}></SimpleBlock>
            default:
              return <div key={block.id}></div>
          }
        })
        )}
      </div>
    )
  }
}

export default Blocks
