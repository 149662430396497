import Layout from "../components/layout"
import Blocks from "./blocks/blocks"
import React from "react"

const TemplateHome = ({pageContext: context}) => {
  const {page} = context;
  const {media} = context;

  return (
    <Layout categories={context.faqCategories} territeoUrl={context.territeoUrl}>
      <div id="blocs">
        <Blocks data={page.blocks} media={media}></Blocks>
      </div>
    </Layout>
  )
}

export default TemplateHome;
